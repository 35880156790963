import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import '../../Cards/Poker/GameTitle.scss'
import Pill from '../../Cards/Poker/Pill'
import skills from '../../game-skills'
import gameDescription from '../../games-description'

const GameTitle: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      th: file(relativePath: { eq: "thumbnails/ABCRummy-Thumbnails.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <div id="game-title">
      <div className="info">
        <div className="game-icon">
          <GatsbyImage
            image={data.th.childImageSharp.gatsbyImageData}
            loading="eager"
            alt="Carrom"
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
        <div className="text">
          <h1 className="name">ABC Rummy</h1>
          <div className="pills">
            {skills.ABCRummy.map(s => {
              return <Pill key={s}>{s}</Pill>
            })}
          </div>
        </div>
      </div>
      <div className="description">{gameDescription.ABCRummy.long}</div>
    </div>
  )
}

export default GameTitle
