import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ABCRummyPage from '../../components/Games/Casual/ABCRummy/ABCRummy'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { CASUAL, GAMES, ABC_RUMMY } from '../../components/internal-links'
import { ABCRUMMY } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'ABC Rummy',
    url: ABC_RUMMY,
  },
]

const ABCRummy: React.FC = () => {
  const [abcrummyFaqsSt, setAbcrummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ abcrummyFaqs }) =>
      setAbcrummyFaqsSt(abcrummyFaqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={ABCRUMMY}>
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="ABC Rummy: Make Letter Sets & Sequences and Win Real Money | Mega"
        description="Make sets & sequences to win real cash! Download Mega to play ABC Rummy - a fun new way to play Rummy, just with English letters. Real players, real money, real fun!"
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(abcrummyFaqsSt)}
      />
      <ABCRummyPage />
    </Layout>
  )
}

export default ABCRummy
