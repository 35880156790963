import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import '../../Cards/Poker/Poker.scss'
import { Container } from 'react-bootstrap'
import GamesIntro from '../../GamesIntro/GamesIntro'
import Contests from '../../Contests'
import GameTestimonials from '../../Cards/Poker/GameTestimonials'
import OtherGames from '../../Cards/Poker/OtherGames'
import DownloadBottomSheet from '../../../Download/DownloadBottomSheet'
import DesktopTitle from './DesktopTitle'
import ABCRummyInfo from '../../CategoryAndGamesInfo/ABCRummyInfo'
import HomeFAQs from '../../../Index/HomeFAQs/HomeFAQs'
import { Faq } from '../../../FAQs/faqs-list-interface'
import useTestimonials from '../../../Testimonials/useTestimonials'
import { ABCRUMMY } from '../../../download-links'
import InfoCard from '../../CategoryAndGamesInfo/InfoCard'
import { recsGameNames } from '../../Cards/Poker/useRecommendGames'
import PaymentPartners from '../../../Index/PaymentPartners/PaymentPartners'
import Features from '../../../Index/Features/Features'
import SafeAndCertified from '../../../Index/SafeAndCertified/SafeAndCertified'

const ABCRummy: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      intro: file(relativePath: { eq: "game-banners/Abc rummy.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const [abcrummyFaqsSt, setAbcrummyFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../../FAQs/faqs-list').then(({ abcrummyFaqs }) =>
      setAbcrummyFaqsSt(abcrummyFaqs)
    )
  }, [])

  const [showDownload, setShowDownload] = useState(false)

  const hideBottomSheet = (): void => {
    setShowDownload(false)
  }

  const { abcRummyTestimonials } = useTestimonials()

  return (
    <div id="poker">
      <GamesIntro
        thumbnail={data.intro.childImageSharp.gatsbyImageData}
        addImageMask
        gameName="ABCRummy"
        apkLink={ABCRUMMY}
      />
      <DesktopTitle />
      <Container>
        <InfoCard>
          <ABCRummyInfo />
        </InfoCard>
      </Container>
      <Contests gameName="ABCRummy" />
      <PaymentPartners />
      <Features />
      <SafeAndCertified hideGame />
      <GameTestimonials testimonials={abcRummyTestimonials} />
      <div className="faqs-wrap">
        <HomeFAQs faqs={abcrummyFaqsSt} />
      </div>
      <OtherGames gameName={recsGameNames.ABCRummy} category="Casual" />
      <DownloadBottomSheet
        show={showDownload}
        hideBottomSheet={hideBottomSheet}
        downloadLink={ABCRUMMY}
      />
    </div>
  )
}

export default ABCRummy
