import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const ABCRummyInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about ABC Rummy</h2>
      <p>
        What if there was an exciting new way to play Rummy — without the
        worrying about suits, rankings, and random wild cards? The game of Rummy
        without the 52 cards, but just the 26 letters of the alphabet - the
        regular ABC to XYZ?
      </p>
      <p>
        Mega brings to you ABC Rummy — a casual game of letters, and a whole new
        way to play Rummy — with a real money twist. Form letter sets and
        sequences faster than others and win real money!
      </p>
      <p>
        Played Rummy before? Yes or no, doesn’t matter. ABC Rummy is easy to
        understand and fun to play. All you need to play is an android phone, an
        eye for patterns and the ability to make quick decisions. Fair enough?
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Download Mega to play ABC Rummy, a fun new casual game inspired by the
        classic game of Rummy. Simply make letter sets and sequences faster than
        anyone else, and win real cash!
      </p>

      <h3>How to Play</h3>
      <ul>
        <li>
          <p>
            Arrange the given letters into perfect sets/sequences, faster than
            your opponents.
          </p>
        </li>
        <li>
          <p>
            Each player is dealt a hand of 9 letters and starts with 90 points.
            Tap on the grid to reveal a new letter or tap to pick from any of
            the open letters. Exchange it with any one of the letters in your
            hand or discard the new pick, before the timer runs out. The
            discarded letter goes back face up on the grid.
          </p>
        </li>
        <li>
          <p>
            Arrange the letters in your hand to make sets and sequences. A set
            is 3 letters of the same kind e.g. AAA, BBB, XXX. A sequence is 3
            letters in the alphabetical order e.g. ABC, BCD, XYZ, YZA. Use the
            sort button to arrange the letters in increasing order for an easy
            view.
          </p>
        </li>
        <li>
          <p>
            The wild letter can be used as a replacement for any particular
            letter to complete the set/sequence (Similar to how a Joker is used
            in traditional Rummy).
          </p>
        </li>
        <li>
          <p>
            The value of each set/sequence is 30 points. The first player to
            successfully declare i.e. make 3 valid sets/sequences, wins.
          </p>
        </li>
        <li>
          <p>
            Since the winner has successfully made 3 sets/sequences, their score
            is 0. The remaining players points are totalled to calculate the
            cash prize.
          </p>
        </li>
      </ul>

      <h3>A fun new way to play Rummy</h3>
      <p>
        ABC Rummy retains the best elements of Rummy - skill and strategy in a
        fun, fast-paced skill- based format for multiple players, but also
        refines it into a lighter, easier to understand game. No more
        remembering pure/impure sequences &amp; randomly assigned jokers, or
        worrying about properly grouping cards. Just plain, simple letters - A,
        B, C, D - easy to sort and arrange. Ready to defeat your opponents with
        your skills and win real cash?
      </p>

      <h3>A thrilling adventure, for everyone</h3>
      <p>
        With a design inspired by the ancient runes and hidden treasures, ABC
        Rummy is exciting, colourful &amp; full of nail-biting thrills. It
        features smooth graphics and user-friendly controls.
      </p>
      <p>
        The ‘sort’ feature allows you to sort your letters quickly, so you can
        focus purely on gameplay and strategy.The buy-in amounts at ABC Rummy
        tables start as low as ₹1, so whatever be the level of your comfort, we
        make sure there is a table for you.
      </p>

      <h3>Real players, Real fun</h3>
      <p>
        Mega allows only 100% verified profiles to play on the app. ABC Rummy is
        played between 2-4 players in quick short rounds, so that you can have a
        fun casual game without any worries, any number of times. Not bots, no
        tricks, no trolls, only pure fun.
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="ABC Rummy"
      />
    </div>
  )
}

export default ABCRummyInfo
